import { extendTheme } from "@chakra-ui/react";

const fontString =
  '"Plus Jakarta Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;';

export default extendTheme({
  config: {
    initialColorMode: "system",
    useSystemColorMode: true,
  },
  fonts: {
    body: fontString,
    heading: fontString,
  },
});
